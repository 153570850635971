// Copyright 2024 The LUCI Authors.
//
// Licensed under the Apache License, Version 2.0 (the "License");
// you may not use this file except in compliance with the License.
// You may obtain a copy of the License at
//
//      http://www.apache.org/licenses/LICENSE-2.0
//
// Unless required by applicable law or agreed to in writing, software
// distributed under the License is distributed on an "AS IS" BASIS,
// WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
// See the License for the specific language governing permissions and
// limitations under the License.

import { HelpOutline } from '@mui/icons-material';
import { Box } from '@mui/material';

import { HtmlTooltip } from '@/common/components/html_tooltip';

export interface TabLabelProps {
  readonly label: string;
  readonly tooltipText: string;
}

export function TabLabel({ label, tooltipText }: TabLabelProps) {
  return (
    <Box sx={{ display: 'flex', alignItems: 'center', gap: '5px' }}>
      <span> {label}</span>
      <HtmlTooltip title={tooltipText}>
        <HelpOutline fontSize="small" />
      </HtmlTooltip>
    </Box>
  );
}
